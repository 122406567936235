<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import AboutOrder from "@/components/about-order";
import Comments from "@/views/pages/order/comments";
import Interventions from "@/views/pages/order/interventions"
import * as XLSX from 'xlsx';
import axios from 'axios';
import {
  orderMethods,
  orderGetters,
  globalGetters,
} from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader,
    AboutOrder,
    Comments,
    Interventions
  },
  data() {
    return {
      mediacalFields:
      [
        { key: "question", label: "Question" },
        { key: "answer", label: "Answer"},
        { key: "explanation",label: "Explanation" },
      ],
      confirmationFields:
      [
        { key: "question", label: "Question" },
        { key: "answer", label: "Answer"},
      ],
      selected: null,
      options: [
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
      ],
      selected_country: null,
      countries: [
        { value: null, text: 'Please select an option' },
        { value: 'United Kingdom (Mainland)', text: 'United Kingdom (Mainland)' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      age_verification_options: [
          { value: 'verified', text: 'Verified' },
          { value: 'unverified', text: 'Unverified' },
          { value: 'not_attempt', text: 'Not Attempt' },
          { value: 'manually_updated', text: 'Manually_updated' }
        ],
    patientDetail:{},
    patientMedicalQns:{},
    patientConfirmationQns:{},
    dob:null,
    loading:false,
    pdfData : {loader1:false,loader2:false,loader3:false,data:{}},
    enableStatusEdit:true,
    }
  },
  created(){
  },
  beforeRouteEnter(to, from, next) {
    next(vm => { 
        vm.$store.dispatch('order/setOrder',to.params.id);
    });
  },
  computed: {
    ...orderGetters,
    ...globalGetters,
    orderId(){
      return this.$route.params.id;
    },
    patientName(){
      return this.patientDetails.first_name+' '+this.patientDetails.last_name;
    },
    weightRelatedVal(){
      return `I have previously taken ${this.product.post_title} and my starting (baseline) BMI was above 30.`
    },
    patientAddress(){
      return `${this.patientDetails.streetaddress}, ${this.patientDetails.postcode}`
    },
    age_checked:{
      get() {
        return this.$store.state.order.age_checked;
      },
      set(value) {
        this.$store.commit('order/CHANGESAGECHECKED', value)
      }
    },
    intervention:{
      get() {
       return this.$store.state.order.intervention
      },
      set(newValue) {
        console.log(newValue)
      }
    }
  },
  async mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId == "patientDetails"){
        let {first_name,last_name,gender,date_of_birth,bmi,postcode,patient_bmi_height_unit,patient_bmi_weight_unit,pregnant,patient_height,patient_weight,building,streetaddress,city,streetaddress2,state,country,previously_taking_medicine,weight_related_comorbidity,weight_related_comorbidity_explanation} =  this.patientDetails;
        this.patientDetail = {
          first_name,last_name,gender,date_of_birth,bmi,postcode,patient_bmi_height_unit,patient_bmi_weight_unit,pregnant,patient_height,patient_weight,building,streetaddress,city,streetaddress2,state,country,previously_taking_medicine,weight_related_comorbidity,weight_related_comorbidity_explanation
        };
      }
      if(modalId == "patientmMedicalDetails"){
         let details = {...this.patientConfirmationQa} ;
         this.patientMedicalQns = details
      }
      if(modalId == "patientConfirmationQuestions"){
         let answers = [...this.patientConfirmationQa] ;
         this.patientConfirmationQns = answers;
      }

      if(modalId == "shippingInformation"){
        let shippingData = {...this.shippingInfo} 
        this.shipping = shippingData
      }
    })
  },
  methods: {
    ...orderMethods,
    handleSubmit(){
      let data = { 
          type : 'patient_detail',
          data : this.patientDetail,
          orderID : this.$route.params.id
        }
      this.editOrder(data);
    },
    handleMedicalQuestion(){
      let data = { 
          type : 'patient_medical_qa',
          data : this.patientMedicalQns,
          orderID : this.$route.params.id
        }
      this.editOrder(data);
    },
    handleConfirmationQuestions(bvModalEvent){
      this.$refs.patientConfirmationButton.click()
      bvModalEvent.preventDefault()
    },
    submitConfirmationQuestions(){
      console.log('submitted')
    },
    removeValueOnChecked(event){
      if(!event){
        this.patientDetail.weight_related_comorbidity_explanation = '';
      }
    },
    changeStatus(){
      console.log(this.orderStatus)
    },
    handleIntervention(){
      this.editIntervention(this.$route.params.id);
    },
    handleAgeVerification(){
      let data = {
        age_checked:this.age_checked,
        post_id : this.$route.params.id
      }
      this.editAgeChecked(data);
    },
    async handlePdf(type){
      if(type == 'generatePatientInfo'){
        this.pdfData.loader1 = true;
      }else if(type == 'generateShippingNote')
      {
        this.pdfData.loader2 = true;
      }else{
        this.pdfData.loader3 = true;
      }

      let data = { order_id: this.$route.params.id,type} 
      this.downloadPdf(data).then(response => {
        this.pdfData.loader1 = false;
        this.pdfData.loader2 = false;
        this.pdfData.loader3 = false;
        this.pdfData.data = response;
      });
    },
    async generateCsv() {
    let start = 1;
    let loop = true;
    let isFirstChunk = true;

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    while (loop) {
        let response = await axios.get(`/wp-json/crm/v1/generate-csv?page=${start}&v=3`);
        const data = response.data;

        if (data.length === 0) {
            loop = false;
            break; // No more data to fetch
        }

        if (isFirstChunk) {
            XLSX.utils.sheet_add_json(worksheet, data, { origin: -1 });
            isFirstChunk = false;
        } else {
            XLSX.utils.sheet_add_json(worksheet, data, {skipHeader: true, origin: -1 });
        }

        console.log(`Downloaded ${data.length} records`);

        start = start + 1;
    }

  

    // Write the workbook to a binary buffer
    const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

    // Convert buffer to a Blob and create a download link
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);

    downloadLink.href = url;
    downloadLink.download = 'products.xlsx';

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    console.log('Download complete');
}

  }
};
</script>

<template>
  <Layout>

  

    
    <!-- <button @click="generateCsv">Download CSV</button> -->
    <PageHeader v-if="orderData && !loader" :title="'DETAILS OF ORDER #'+orderData.post_name" />
      <div class="loader-wrap text-center h-100vh position-relative" v-if="loader">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
      </div>
      <div class="order-details-wrap" v-if="!loader">
        <div class="download_pdf mb-3 d-flex align-items-center">
           <div class="d-flex">
            <b-button class="" type="submit" @click="handlePdf('generatePatientInfo')" variant="primary">Generate Patient Info PDF
              <b-spinner v-if="pdfData.loader1" small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
            <b-button class="ml-2" type="submit" @click="handlePdf('generateShippingNote')" variant="primary">Generate Shipping Note PDF
              <b-spinner v-if="pdfData.loader2" small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
            <b-button class="ml-2" type="submit" @click="handlePdf('generateGPInfo')" variant="primary">Generate GP PDF
              <b-spinner v-if="pdfData.loader3" small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
          </div>
            <a v-if="getPDFURL && (pdfData.loader1 == false || pdfData.loader2 == false)" class="ml-2 btn btn-outline-success" :href="getPDFURL" download="document.pdf" target="_blank">
              <span v-if="pdfData.data.type == 'generatePatientInfo'">Click to Download Patient Info PDF</span>
              <span v-if="pdfData.data.type == 'generateShippingNote'">Click to Download Shipping Info PDF</span>
              <span v-if="pdfData.data.type == 'generateGPInfo'">Click to Download Patient GP Info</span>
            </a>
        </div>

        <AboutOrder :data="orderData" :product="product" :patientname="patientName" :patientadrress="patientAddress" :orderId="orderId" :patientInfo="patientDetails" />
        <div class="card">
          <div class="card-body">
            <template v-if="loader">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>

            <b-tabs justified nav-class="nav-tabs-custom" content-class=" mt-3 text-muted" v-if="!loader">
              <b-tab active title-link-class="pl-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-block text-left font-weight-bolder font-size-17">Order Details</span>
                </template>               

                <table class="table table-striped">
                    <tbody>
                      <tr v-for="(variation,index) in productDetails.variation" :key="index">
                        <th scope="row">{{variation.variation_name}} - {{variation.name}} <small>{{ variation.expiry ? '(Expiry: ' + variation.expiry + ')' : '' }}</small></th>
                        <td style="text-align:right">{{currency+variation.price}}</td>
                      </tr>
                      <tr>
                        <th scope="row">Shipment</th>
                        <td style="text-align:right">{{currency+shipping.cost}}</td>
                      </tr>
                      <tr v-if="productDetails.coupon_title">
                        <th scope="row" class="">Discount ({{productDetails.coupon_title}})</th>
                        <td style="text-align:right">{{currency+productDetails.discount}}</td>
                      </tr>
                      <tr>
                        <th scope="row" class="">Total</th>
                        <td style="text-align:right">{{currency+productDetails.order_total}}</td>
                      </tr>
                    </tbody>
                </table>
              </b-tab>
              <b-tab title-link-class="pl-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-block text-left font-weight-bolder font-size-17">Patient Detail</span>
                </template>
                <table class="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">First Name</th>
                    <td>{{patientDetails.first_name}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Last Name</th>
                    <td>{{patientDetails.last_name}}</td>

                  </tr>
                  <tr>
                    <th scope="row">Post Code</th>
                    <td colspan="2">{{patientDetails.postcode}}</td>

                  </tr>
                  <tr>
                    <th scope="row">Gender</th>
                    <td colspan="2">{{patientDetails.gender}}</td>

                  </tr>
                  <tr>
                    <th scope="row">Pregnant, planning a pregnancy or breasfeeding</th>
                    <td colspan="2">{{patientDetails.pregnant}}</td>

                  </tr>
                  <tr>
                    <th scope="row">Date Of Birth</th>
                    <td colspan="2">{{patientDetails.date_of_birth}}</td>

                  </tr>
                  <tr>
                    <th scope="row">Patient BMI Height Unit</th>
                    <td colspan="2">{{patientDetails.patient_bmi_height_unit}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Patient BMI Weight Unit</th>
                    <td colspan="2">{{patientDetails.patient_bmi_weight_unit}}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="fw-bold" colspan="2"><strong>Patient Height</strong></th>
                  </tr>
                  <tr>
                    <th scope="row">Value 1</th>
                    <td >{{patientDetails.patient_height.value_1}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Value 2</th>
                    <td>{{patientDetails.patient_height.value_2}}</td>
                  </tr>
                  <tr>
                    <th scope="row" colspan="2" class="fw-bold"><strong>Patient Weight</strong></th>
                    <!-- Value 1 -->
                  </tr>
                  <tr>
                    <th scope="row">Value 1</th>
                    <td>{{patientDetails.patient_weight.value_1}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Value 2</th>
                    <td>{{patientDetails.patient_weight.value_2}}</td>
                  </tr>

                  <tr>
                    <th scope="row">BMI</th>
                    <td>{{patientDetails.bmi}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Address1</th>
                    <td>{{patientDetails.streetaddress}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Address2</th>
                    <td >{{patientDetails.streetaddress2}}</td>
                  </tr>
                  <tr>
                    <th scope="row">City</th>
                    <td>{{patientDetails.city}}</td>
                  </tr>
                  <tr>
                    <th scope="row">State</th>
                    <td>{{patientDetails.state}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Postcode</th>
                    <td>{{patientDetails.postcode}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Previously Taking Medicine</th>
                    <td>{{patientDetails.previously_taking_medicine}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Weight Related Comorbidity</th>
                    <td>{{patientDetails.weight_related_comorbidity}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Weight Related Comorbidity Explanation</th>
                    <td>{{patientDetails.weight_related_comorbidity_explanation}}</td>
                  </tr>
              </tbody>
             </table>
              </b-tab>

              <b-tab title-link-class="pl-0">
                <template v-slot:title >
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-block text-left font-weight-bolder font-size-17">Medical Questions</span>
                </template>
              <template>
                <div>
                  <b-table striped :items="patientMedicalQa" :fields="mediacalFields"></b-table>
                </div>
              </template>
              </b-tab>

              <b-tab title-link-class="pl-0">
                <template v-slot:title >
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-block text-left font-weight-bolder font-size-17">Confirmation Questions</span>
                </template>
              <template>
                <div>
                  <b-table striped :items="patientConfirmationQa" :fields="confirmationFields"></b-table>
                </div>
              </template>
              </b-tab>


              <b-tab title-link-class="pl-0">
                <template v-slot:title >
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-block text-left font-weight-bolder font-size-17">Shipping Information</span>
                </template>
                <table class="table table-striped">
     
                    <tbody>
                      <tr>
                        <th scope="row">First Name</th>
                        <td>{{ shippingInfo.first_name }}</td>
                      </tr>
                      <tr>
                        <th scope="row">Last Name</th>
                        <td>{{ shippingInfo.last_name }}</td>

                      </tr>
                      <tr>
                        <th scope="row">Country</th>
                        <td colspan="2">{{ shippingInfo.country }}</td>

                      </tr>
                      
                      <tr>
                        <th scope="row">Postal Code</th>
                        <td colspan="2">{{ shippingInfo.postal_code }}</td>

                      </tr>
                      <tr>
                        <th scope="row">Address 1</th>
                        <td colspan="2">{{ shippingInfo.address_1 }}</td>

                      </tr>
                      <tr>
                        <th scope="row">Address 2</th>
                        <td colspan="2">{{ shippingInfo.address_2 }}</td>

                      </tr>
                      <tr>
                        <th scope="row">City</th>
                        <td colspan="2">{{ shippingInfo.city }}</td>

                      </tr>
                      <tr>
                        <th scope="row">State/Province /Region</th>
                        <td colspan="2">{{ shippingInfo.state__province__region }}</td>
                      </tr>

                      <tr>
                        <th scope="row">Phone Number</th>
                        <td colspan="2">{{ shippingInfo.phone_number }} </td>
                      </tr>
                    </tbody>

                  </table>
              </b-tab>
            </b-tabs>
          </div>
        </div>
  
        <div class="card" v-if="gpDetails">
          <div class="card-body">
           <div class="gp_details">
              <h4>Patient GP Details</h4>
              <table class="table table-striped">     
                <tbody>
                 <template v-if="gpDetails.questions">
                  <tr v-for="(question,index) in gpDetails.questions" :key="index">
                    <th scope="row">Question</th>
                    <td>{{ question.question }}</td>
                    <th scope="row">Answer</th>
                    <td>{{ question.answer }}</td>
                  </tr>
                </template>
                  <tr>
                    <th scope="row">Post Code</th>
                    <td colspan="3">{{ gpDetails.post_code }}</td>
                  </tr>
                  <tr>
                    <th scope="row">GP Type</th>
                    <td colspan="3">{{ gpDetails.gp_type }}</td>
                  </tr>
                   <template v-if="gpDetails.questions[0]['answer'] == 'yes' && gpDetails.gp_type == 'manual'">
                      <tr>
                        <th scope="row">Surgery Name</th>
                        <td colspan="3">{{ gpDetails.manual_details.surgery_name }}</td>

                      </tr>
                      <tr>
                        <th scope="row">Surgery Address</th>
                        <td colspan="3">{{ gpDetails.manual_details.surgery_address }}</td>

                      </tr>
                      <tr>
                        <th scope="row">GP Email</th>
                        <td colspan="3">{{ gpDetails.manual_details.email }}</td>
                      </tr>
                      <tr>
                        <th scope="row">GP Fax Number</th>
                        <td colspan="3">{{ gpDetails.manual_details.fax_number }}</td>
                      </tr>
                  </template>
                  <template v-if="gpDetails.questions[0]['answer'] == 'yes' && gpDetails.gp_type == 'auto'">
                  <tr>
                    <th scope="row">City</th>
                    <td colspan="3">{{ gpDetails.selected_gp.Name }}</td>

                  </tr>
                  <tr>
                    <th scope="row">Organisation ID</th>
                    <td colspan="3">{{ gpDetails.selected_gp.OrgId }}</td>
                  </tr>

                  <tr>
                    <th scope="row">Primary Role ID</th>
                    <td colspan="3">{{ gpDetails.selected_gp.PrimaryRoleId }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Primary Role Description</th>
                    <td colspan="3">{{ gpDetails.selected_gp.PrimaryRoleDescription }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Organisation Link</th>
                    <td colspan="3">{{ gpDetails.selected_gp.OrgLink }}</td>
                  </tr>
                </template>
                </tbody>

              </table>
            </div>
            </div>
        </div>

        <Comments />
        <Interventions />

       <div class="card">
          <div class="card-body">
              <b-row>
                 <b-col >
                    <h4>Age Verfication Status</h4>
                    <b-form @submit.prevent="handleAgeVerification()">
                    <b-form-group>
                       <b-form-select v-model="age_checked" :options="age_verification_options"></b-form-select>
                    </b-form-group> 
                    <b-button class="mt-2" type="submit" variant="primary">Submit</b-button>
                  </b-form>
                 </b-col>
              </b-row>
          </div>
      </div>
      
    </div>
      <div class="d-none">
        <b-button v-b-modal.patientDetails>Edit</b-button>
        <b-button v-b-modal.patientmMedicalDetails>Medical Qusetions</b-button>
        <b-button v-b-modal.patientConfirmationQuestions>Medical Qusetions</b-button>
        <b-button v-b-modal.shippingInformation>shipping Information</b-button>
      </div>


        <b-modal
          size="lg"
          centered 
          id="patientDetails"
          ref="modal"
          title="Submit Your Name"
          hide-footer
        >
          <form ref="contactDetailsForm" @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col>
              <b-form-group
                label="First Name"
                label-for="first-name"
                invalid-feedback="First Name is required"
              >
                <b-form-input
                  id="first-name"
                  v-model="patientDetail.first_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Last Name"
                label-for="last-name"
                invalid-feedback="Last Name is required"
              >
                <b-form-input
                  id="last-name"
                  v-model="patientDetail.last_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label="Post Code"
                label-for="post-code"
                invalid-feedback="Post Code is required"
              >
                <b-form-input
                  id="post-code"
                  v-model="patientDetail.postcode"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Gender"
                label-for="gender"
                invalid-feedback="Gender is required"
              >
              <b-form-select v-model="patientDetail.gender" :options="options"></b-form-select>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label="Date Of Birth"
                label-for="d-o-b"
                invalid-feedback="Date Of Birth is required"
              >
              <b-form-datepicker id="d-o-b" v-model="patientDetail.date_of_birth" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="BMI"
                label-for="bmi"
                invalid-feedback="BMI is required"
              >
                <b-form-input
                  id="bmi"
                  required
                  v-model="patientDetail.bmi"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label="Address1"
                label-for="address1"
                invalid-feedback="Address1 is required"
              >
                <b-form-input
                  id="bmi"
                  required
                  v-model="patientDetail.streetaddress"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Address2"
                label-for="address2"
                invalid-feedback="Address2 is required"
              >
                <b-form-input
                  id="streetaddress2"
                  required
                  v-model="patientDetail.streetaddress2"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label=""
                label-for="previously_taken_medicine"
              >
              <b-form-checkbox
                id="previously_taken_medicine"
                v-model="patientDetail.previously_taking_medicine"
                name="previously_taken_medicine"
                :value="weightRelatedVal"
              >
              I have previously taken Saxenda and my starting (baseline) BMI was above 30.*
              </b-form-checkbox>
            </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label=""
                label-for="one_weight_related_comorbidity"
              >
                <b-form-checkbox
                  @change="removeValueOnChecked($event)"
                  id="one_weight_related_comorbidity"
                  v-model="patientDetail.weight_related_comorbidity"
                  name="checkbox-1"
                  value="I have at least one weight related comorbidity (e.g. diabetes, pre-diabetes, high cholesterol, dyslipidemia, hypertension, sleep apnoea etc)"
                >I have at least one weight related comorbidity (e.g. diabetes, pre-diabetes, high cholesterol, dyslipidemia, hypertension, sleep apnoea etc)</b-form-checkbox>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col v-if="patientDetail.weight_related_comorbidity">
              <b-form-group
                label="Weight Related Comorbidity Explanation"
                label-for="weight_related_comorbidity_explanation"
              >
                <b-form-input
                  id="weight_related_comorbidity_explanation"
                  required
                  v-model="patientDetail.weight_related_comorbidity_explanation"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

          </b-row>
          <!-- <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close Me</b-button> -->
          <b-button type="button" block class="btn btn-primary w-25px" v-if="loading">
            <div  class="custom-loader mx-auto"></div>
          </b-button>
          <b-button type="submit" block variant="primary" class="btn btn-primary" v-else ref="contactDetailsFormButton">
              Save
          </b-button>
          </form>
        </b-modal>


        <b-modal
          size="lg"
          centered 
          id="patientmMedicalDetails"
          ref="modal"
          title="Edit Patient Mediacal Questions"
        >
          <form ref="patientMediacalQuestionsForm" @submit.stop.prevent="handleMedicalQuestion">
            <b-row>
              <b-col>
              <div class="px-3 mb-4" v-for="question,i in patientMedicalQns" :key="question.question">
                <b-form-group :label="++i+'. '+question.question" size="lg">
                  <b-form-radio v-model="question.answer" :name="question.question" value="yes">Yes</b-form-radio>
                  <b-form-radio v-model="question.answer" :name="question.question" value="no">No</b-form-radio>
                </b-form-group>
              
                <b-form-group v-if="question.explanation && question.answer == 'yes'"
                label="Explaination"
                label-for="explaination"
              >
                <b-form-input
                  id="explaination"
                  required
                  v-model="question.explanation"
                ></b-form-input>
              </b-form-group>

              </div>
            </b-col>  
            <b-button type="submit" block variant="primary" class="btn btn-primary">
              Save
          </b-button>          
          </b-row>
          </form>
        </b-modal>


        <b-modal
          size="lg"
          centered 
          id="patientConfirmationQuestions"
          ref="modal"
          title="Edit Patient Confirmation Questions"
          @ok="handleConfirmationQuestions"
        >
          <form ref="patientConfirmationQuestionsForm" @submit.prevent="submitConfirmationQuestions">
            <b-row>
              <b-col>
              <div class="px-3 mb-4" v-for="question,i in patientConfirmationQns" :key="question.question">
                <b-form-group :label="++i+'. '+question.question" size="lg">
                  <b-form-radio v-model="question.answer" :name="question.question" value="yes">Yes</b-form-radio>
                  <b-form-radio v-model="question.answer" :name="question.question" value="no">No</b-form-radio>
                </b-form-group>
              </div>
            </b-col>            
          </b-row>
          <button type="submit" ref="patientConfirmationButton"></button>
          </form>
          
        </b-modal>

        <b-modal
          size="lg"
          centered 
          id="shippingInformation"
          ref="modal"
          title="Edit Shipping Information"
        >
          <form ref="shippingInformationForm" @submit.stop.prevent="handleShippinginformation">
            <b-row>
              <b-col>
              <b-form-group
                label="First Name"
                label-for="first-name"
                invalid-feedback="First Name is required">
                <b-form-input
                  id="first-name"
                  v-model="shipping.first_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Last Name"
                label-for="last-name"
                invalid-feedback="Last Name is required"
              >
                <b-form-input
                  id="last-name"
                  v-model="shipping.last_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label="Country"
                label-for="country"
                invalid-feedback="Country is required"
              >
              <b-form-select id="country" v-model="shipping.country" :options="countries"  required></b-form-select>
              </b-form-group>
            </b-col>
            

            <b-col>
              <b-form-group
                label="Post Code"
                label-for="post-code"
                invalid-feedback="Post Code is required"
              >
                <b-form-input
                  id="post-code"
                  v-model="shipping.postal_code"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label="Address 1"
                label-for="address1"
              >
              <b-form-input
                  id="address1"
                  v-model="shipping.address_1"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Address 2"
                label-for="address2"
              >
              <b-form-input
                  id="address2"
                  v-model="shipping.address_2"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label="City"
                label-for="city"
              >
              <b-form-input
                  id="city"
                  v-model="shipping.city"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="State/Province /Region"
                label-for="state_province_region	"
                invalid-feedback="State/Province /Region is required"
              >
                <b-form-input
                  id="state_province_region"
                  required
                  v-model="shipping.state__province__region"

                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="w-100"></div>

            <b-col>
              <b-form-group
                label="Phone Number"
                label-for="phone_number"
                invalid-feedback="State/Province /Region is required"
              >
                <b-form-input
                  id="phone_number"
                  required
                  v-model="shipping.phone_number"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          </form>
        </b-modal>


  </Layout>
</template>

<style>
#patientmMedicalDetails .modal-body fieldset div,#patientConfirmationQuestions .modal-body fieldset div {
    display: flex;
    column-gap: 14px;
}
#patientmMedicalDetails .modal-body fieldset legend,#patientConfirmationQuestions .modal-body fieldset legend {
    font-size: 16px;
    font-weight: 600;
}
#patientmMedicalDetails .modal-body,#patientConfirmationQuestions .modal-body {
    max-height: 600px;
    overflow-y: auto;
}
.timeline-date {
    display: inline-block;
    padding: 4px 16px 4px 8px;
    border-radius: 0px 30px 30px 0px;
    background-color: #5664d2;
    color: #fff;
    position: relative;
    left: -30px;
}
</style>


