<script>
import {
  ordersMethods,
  orderMethods
} from "@/state/helpers";
import Swal from 'sweetalert2';
// import axios from 'axios';
export default {
  props:['data','product','patientname','patientadrress', 'patientInfo'],
  data() {
    return {
       enableStatusEdit:true,
       orderStausOptions:[
        { value: 'pending', text: 'Pending' },
        { value: 'processing', text: 'Processing' },
        { value: 'approved', text: 'Approved' },
        { value: 'canceled', text: 'Canceled' },
        { value: 'on hold', text: 'On Hold'},
        { value: 'cancelled', text: 'Cancelled'}
      ],
      submitted: false,
      showmodal: false,
      actionsModal:false,
      sendToOther:false,
      emailLoader : false,
      emailto: 'customer',
      email:'',
      orderId:0,
      options: [
        { item: 'first_name', name: 'Filter By First Name' },
        { item: 'last_name', name: 'Filter By Last Name' },
        { item: 'email', name: 'Filter By Email' },
        { item: 'order_id', name: 'Filter By Order ID' },
        { item: 'postcode', name: 'Filter By Postcode' },
        { item: 'bmi', name: 'Filter By BMI' },
      ],
    }
  },
  computed: {
    orderStatus: {
      get() {
        return this.$store.state.order.status
      },
      set(value) {
        this.$store.commit('order/CHANGESTATUS', value)
      }
    },
    totalUserOrders(){
      return this.$store.state.order.total_orders;
    },
    transaction_id(){
      return this.$store.state.order.productDetails.transaction_id;
    }
  },
  mounted() {
 
  },
  methods: {
    ...ordersMethods,
    ...orderMethods,
    handleSubmit(){
      let data = { 
          type : 'patient_detail',
          data : this.patientDetail,
          orderID : this.$route.params.id
        }
      this.editOrder(data);
    },
    EmailSubmit(){
      this.emailLoader = true;
      this.sendEmail({
        order_id:this.orderId,
        emailto:this.emailto,
        sendToOther:this.sendToOther,
        email:this.email
      }).then(response => {
        if(response.success){
           this.$swal({
              toast: true,
              title: response.message,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
              }
           }); 
           this.emailLoader = false;
        }else
        {
          Swal.fire(
          'Failed',
             response.message,
            'error'
          )
          this.emailLoader = false;
        }
      });
    },
    handleMedicalQuestion(){
      let data = { 
          type : 'patient_medical_qa',
          data : this.patientMedicalQns,
          orderID : this.$route.params.id
        }
      this.editOrder(data);
    },
    handleConfirmationQuestions(bvModalEvent){
      this.$refs.patientConfirmationButton.click()
      bvModalEvent.preventDefault()
    },
    submitConfirmationQuestions(){
      console.log('submitted')
    },
    removeValueOnChecked(event){
      if(!event){
        this.patientDetail.weight_related_comorbidity_explanation = '';
      }
    },
    changeStatus(){
      let data = {
        post_id : this.$route.params.id,
        status:this.orderStatus
      }
      this.editStatus(data);
    }
  }
};
</script>

<template>
  <div class="timeline-item">
    <div class="timeline-block">
      <div class="timeline-box card">
        <div class="card-body">
          <span class="timeline-icon"></span>
          <div class="timeline-date">
            <i class="mdi mdi-circle-medium circle-dot"></i>
                {{ this.$store.state.order.date }}
          </div>
          <div class="timeline-email">
          <a href="javascript:;" class="text-white" @click="showmodal = true; email=patientInfo['email']; orderId = data.post_title" v-b-tooltip.hover
                title="Send Email">
                <i class="mdi mdi-email-send font-size-15"></i>
                Send Email
              </a>
          </div>
          <b-modal id="modal-1" centered v-model="showmodal" title="Send Order Email" title-class="text-dark font-18" hide-footer>
            <form @submit.prevent="EmailSubmit()">
              <b-form-group label="Send Email To" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="emailto" :aria-describedby="ariaDescribedby" name="some-radios" class="mb-3"
                  value="customer">Email to Customer</b-form-radio>
                <b-form-radio v-model="emailto" :aria-describedby="ariaDescribedby" name="some-radios" class="mb-3" value="admin">Email to
                  Admin</b-form-radio>
                <b-form-checkbox id="checkbox-1" v-model="sendToOther" name="checkbox-1" value="yes"
                  unchecked-value="no">
                  Send Email to Other
                </b-form-checkbox>
              </b-form-group>
              <div class="form-group" v-if="sendToOther == 'yes'">
                <label for="exampleInputEmail1">{{ (emailto == 'customer') ? 'Send Customer Email To' : 'Send Admin Email To' }}</label>
                <input id="email" v-model="email" type="email" name="username" class="form-control"
                  placeholder="Enter email" />
              </div>
              <div class="text-right">
                <button type="submit" :disabled="emailLoader" class="btn btn-success">
                  <span v-if="emailLoader">Sending...</span>
                  <span v-else>Send</span>
                </button>
              </div>
            </form>
        </b-modal>
          <div class="card border mt-4 rounded active shipping-address">
          <b-row align-v="center">
            <b-col cols="12" md="6" lg="4">
                <div class="card-body">
                  
                  <h5 class="foont-size-15">{{ patientname }}</h5>
                  <p class="mb-0">Order Id: #{{ data.post_title }}</p>
                  <p class="mb-1">{{ patientadrress }}</p>
                </div>
            </b-col>
          <b-col cols="12" md="6" lg="4">
          <div class="card-body">
            <h5 class="foont-size-15">{{product.post_title}}</h5>
            <p class="mb-0" v-if="transaction_id">Transaction ID: {{ transaction_id }}</p>
            <div class="d-flex align-items-center">
            <p class="mb-0 fw-bold">Total No of Orders   
            </p>
            <router-link :to="(totalUserOrders != 0) ? { path: `/user-orders/${data.post_author}`} : '#'" class="ml-3 text-success" v-b-tooltip.hover title="Click to View" >                      
                  <b-badge class="py-1 px-3" variant="primary">{{totalUserOrders}}</b-badge>
              </router-link>
            </div>
            </div>
          </b-col>
          <b-col cols="12" md="12" lg="4">
          <div class="card-body">
            <a href="#" @click="enableStatusEdit = !enableStatusEdit" class="float-right ml-1">Edit</a>
            <h5 class="foont-size-15">Order status</h5>
              <b-form @submit.prevent="changeStatus" class="text-muted d-flex">
                <b-form-select v-model="orderStatus" :options="orderStausOptions"></b-form-select>
                <b-button type="submit" variant="primary" :disabled="enableStatusEdit" class="btn btn-primary ml-3 w-25">Submit</b-button> 
              </b-form>
            </div>
          </b-col>
          </b-row>
        </div>
        </div>
      </div>
    </div>
  </div>          
</template>

<style>
#patientmMedicalDetails .modal-body fieldset div,#patientConfirmationQuestions .modal-body fieldset div {
    display: flex;
    column-gap: 14px;
}
#patientmMedicalDetails .modal-body fieldset legend,#patientConfirmationQuestions .modal-body fieldset legend {
    font-size: 16px;
    font-weight: 600;
}
#patientmMedicalDetails .modal-body,#patientConfirmationQuestions .modal-body {
    max-height: 600px;
    overflow-y: auto;
}
.timeline-date {
    display: inline-block;
    padding: 4px 16px 4px 8px;
    border-radius: 0px 30px 30px 0px;
    background-color: #5664d2;
    color: #fff;
    position: relative;
    left: -30px;
}
.timeline-email {
  display: inline-block;
    padding: 4px 16px 4px 14px;
    border-radius: 30px 0px 0px 30px;
    background-color: #5664d2;
    color: #fff;
    position: relative;
    float: right;
    right: -30px;
}
.timeline-email .mdi-email-send::before{
  content: "\f09de";
}
</style>


