<template>
    <div class="card">
          <div class="card-body">
              <b-row>
                 <b-col>
                    <div v-if="interventions.length > 0">
                    <h4 class="mb-2">Intervention List</h4>
                    <ul class="intervention-list-group list-group icon-demo-content">
                        <li
                            v-for="intervention in interventions"
                            :key="intervention.id"
                            class="list-group-item d-flex justify-content-between align-items-center"
                        >
                            <div>
                            <h5>{{ intervention.issue }}</h5>
                            <p>{{ intervention.reason }}</p>
                            <p>
                                Added by : {{ intervention.user_display_name }}
                                <br />
                                Created at: {{ intervention.created_at }}
                            </p>
                            </div>
                            <div>
                            <span
                                class="material-icons"
                                @mouseover="showEmail(intervention)"
                                @mouseout="hideEmail()"
                            >
                            </span>
                            <span v-b-tooltip.hover :title="intervention.user_email">
                                <i class="mdi mdi-information-variant"></i>
                            </span>
                            <span v-b-tooltip.hover title="Edit" @click="handleEditIntervention(intervention)">
                                <i class="mdi mdi-square-edit-outline"></i>
                            </span>
                            <span v-b-tooltip.hover title="Delete"  @click="handleDeleteIntervention(intervention)">
                                <i class="mdi mdi-delete-outline"></i>
                            </span>
                            </div>
                        </li>
                        </ul> 
                    </div>  

                  <b-form @submit.prevent="handleIntervention">
                    <h4 class="mb-3 mt-md-4 mt-2">Add Intervention</h4>
                    <b-form-group>
                    <h5 class="mb-3">Please select issue</h5>
                    <div class="d-flex">
                      <b-form-radio class="mb-2 mr-3" v-model="intervention.issue" value="bmi_issue">BMI Verification</b-form-radio>
                      <b-form-radio class="mb-2 mr-3" v-model="intervention.issue" value="address_issue">Address Verification</b-form-radio>
                      <b-form-radio class="mb-2 mr-3" v-model="intervention.issue" value="medication_issue">Medication Verification</b-form-radio>
                      <b-form-radio class="mb-2 mr-3" v-model="intervention.issue" value="other">Other</b-form-radio>
                    </div>
                    <div v-if="intervention.issue">
                      <h5 class="my-2">Please specify reason</h5>
                      <b-textarea required v-model="intervention.reason"></b-textarea>
                      <b-button class="mt-2" :type="formLoader ? 'button' : 'submit'" variant="primary">{{  formLoader ? 'Loading...' : 'Submit' }}</b-button>
                    </div>
                   </b-form-group>
                   </b-form> 
                 </b-col>
              </b-row>
          </div>

          <b-modal centered v-model="showEditModal" title="Edit Intervention" hide-footer>
            <b-form @submit.prevent="submitEditIntervention">
                <b-form-group>
                <h5 class="mb-3">Please select issue</h5>
                <div class="d-flex">
                    <b-form-radio class="mb-2 mr-3" v-model="editedIntervention.issue" value="bmi_issue">
                    BMI Verification
                    </b-form-radio>
                    <b-form-radio class="mb-2 mr-3" v-model="editedIntervention.issue" value="address_issue">
                    Address Verification
                    </b-form-radio>
                    <b-form-radio class="mb-2 mr-3" v-model="editedIntervention.issue" value="medication_issue">
                    Medication Verification
                    </b-form-radio>
                    <b-form-radio class="mb-2 mr-3" v-model="editedIntervention.issue" value="other">Other</b-form-radio>
                </div>
                <div v-if="editedIntervention.issue">
                    <h5>Please specify reason</h5>
                    <b-textarea required v-model="editedIntervention.reason"></b-textarea>
                    <b-button class="mt-2" :type="formLoader ? 'button' : 'submit'" variant="primary">{{  formLoader ? 'Loading...' : 'Submit' }}</b-button>
                </div>
                </b-form-group>
            </b-form>
            </b-modal>
      </div>
</template>

<script>
import {
  orderMethods,
  orderGetters
} from "@/state/helpers";
import Swal from 'sweetalert2';
export default {
  components: {
  },
  data() {
    return {
        formLoader : false,
        intervention:{ 
            issue:'',
            reason:'',
            user_id : this.$store.state.authfack.user.ID,
            order_id : this.$route.params.id,
        },
        editedIntervention: {
          issue: '',
          reason: '',
        },
        showEditModal:false,
    }
   },
   computed: {
    ...orderGetters,
   },
   methods:{
    ...orderMethods,
    handleIntervention(){
      this.formLoader = true;  
      this.addIntervention(this.intervention).then((res)=>{ 
         this.formLoader = false;  
         if(res){
          this.intervention.issue = '';
          this.intervention.reason = '';
         }
      });
    },
    handleDeleteIntervention(intervention){
      let options =  { 
        user_id : this.$store.state.authfack.user.ID,
        intervention_id : intervention.id 
      }

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteIntervention(options);
        }
        })
    },
    handleEditIntervention(intervention) {
      this.editedIntervention = { ...intervention };
      this.showEditModal = true;
   },
    submitEditIntervention() {
        this.formLoader = true;
        this.editedIntervention.user_id =  this.$store.state.authfack.user.ID;
        this.editIntervention(this.editedIntervention).then((res)=>{
            if(res){
                this.formLoader = false;
                this.showEditModal = false;
            }
        });
    }
 }
}
</script>

<style scoped>
.icon-demo-content i:hover{
    cursor: pointer;
    background: #252b3b;
    color: #fff;
}
.intervention-list-group p{
    color:#252b3b;
}

</style>