<template>
    <div class="card">
      <div class="card-body">
          <b-row>
             <b-col>
               <div class="comments_list_wrap" v-if="comments.length > 0">
                <h4 class="mb-2">Comments</h4>
                  <div class="comments_list_items">
                    <ul class="data-list-group list-group icon-demo-content mb-3">
                        <li
                            v-for="comment in comments"
                            :key="comment.id"
                            class="list-group-item d-flex justify-content-between align-items-center"
                        >
                            <div>
                            <h6>Comment:</h6>
                            <p>{{ comment.comment }}</p>
                            <p>
                                Name : {{ comment.user_display_name }}
                                <br />
                                Created at: {{ comment.created_at }}
                            </p>
                            </div>
                            <div>
                            <span
                                class="material-icons"
                                @mouseover="showEmail(comment)"
                                @mouseout="hideEmail()"
                            >
                            </span>
                            <span v-b-tooltip.hover :title="comment.user_email">
                                <i class="mdi mdi-information-variant"></i>
                            </span>
                            <span v-b-tooltip.hover title="Edit" @click="handleEditComment(comment)">
                                <i class="mdi mdi-square-edit-outline"></i>
                            </span>
                            <span v-b-tooltip.hover title="Delete"  @click="handleDeleteComment(comment)">
                                <i class="mdi mdi-delete-outline"></i>
                            </span>
                            </div>
                        </li>
                        </ul> 
                    </div>  
               </div>   
                <h4>Add Comments</h4>
                <b-form @submit.prevent="submitCommentsForm()">
                <b-form-group>
                    <b-form-textarea
                    id="textarea"
                    v-model="comment"
                    placeholder="Enter comment..."
                    rows="3"
                    required
                    max-rows="6"
                    ></b-form-textarea>
                </b-form-group> 
                <b-button class="mt-2" type="submit" variant="primary">
                   <span v-if="loading">Loading...</span>
                   <span v-else>Submit</span>
                </b-button>
                </b-form>

            <b-modal centered v-model="showEditModal" title="Edit Comment" hide-footer>
            <b-form @submit.prevent="submitEditComment">
                <b-form-group>
                <div>
                    <h5>Please Enter New Comment</h5>
                    <b-textarea required v-model="editedComment.comment"></b-textarea>
                    <b-button class="mt-2" :type="formLoader ? 'button' : 'submit'" variant="primary">{{  formLoader ? 'Loading...' : 'Submit' }}</b-button>
                </div>
                </b-form-group>
            </b-form>
            </b-modal>

             </b-col>
          </b-row>
        
      </div>
  </div>
</template>
<script>
import {
orderMethods,
orderGetters
} from "@/state/helpers";
export default {
props:{
},
data(){
    return {
      comment : '',
      loading:false,
      formLoader : false,
      editedComment : {},
      showEditModal:false
    }
},
computed:{
    ...orderGetters
},
methods : {
    ...orderMethods,
    submitCommentsForm(){
        if(this.comment == ''){
            alert('Please add Comment');
            return false;
        }
        this.loading = true;
        let request = { comment :this.comment,order_id:this.$route.params.id,user_id : this.$store.state.authfack.user.ID };
        this.addComment(request)
        .then(response => {
            console.log(response)
            this.loading = false;
            this.comment = ''
        })
        .catch(err => {
            console.log(err);
            this.loading = false;
            this.comment = ''
        });
    },
    handleDeleteComment(comment){
      let options =  { 
        user_id : this.$store.state.authfack.user.ID,
        comment_id : comment.id 
      }
      this.deleteComment(options);
    },
    handleEditComment(comment) {
      this.editedComment = { ...comment };
      this.showEditModal = true;
   },
   submitEditComment() {
        this.editedComment.user_id =  this.$store.state.authfack.user.ID;
        this.formLoader = true
        this.editComment(this.editedComment).then(res => {
            console.log(res);
            this.formLoader = false;
            this.showEditModal = false;
        });
    }
},
}
</script>
<style scoped>
.comment_delete_icon{
right: 5px;
font-size: 25px;
top: 18px;
cursor: pointer;
}
.comment_delete_icon:hover{
color:red;
}
.data-list-group p{
  color:  #222;
}
</style>